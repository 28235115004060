<template>
  <v-row class="w-100 w-full" justify="center">
    <v-col cols="12" sm="6" md="5" lg="4">
      <v-sheet
        color="white"
        elevation="4"
        outlined
        rounded
        class="ma-5 pt-7 pl-9 pr-9 rounded-lg"
      >
        <div class="text-center">
          <p class="title"><span class="mt-2">3</span><span>PL</span> / Transporter</p>
        </div>
        <div class="mb-6">
          <span class="headline-6">
            Masuk
          </span>
        </div>
        <v-form ref="loginForm" @submit.prevent="login">
          <v-text-field
            v-model="email"
            ref="email"
            clearable
            :label="$_strings.welcomePage.EMAIL"
            dense
            :readonly="isReadonly"
            :rules="rules.email"
            @focus="clearRulesEmail"
            @blur="blurRulesEmail"
            class="body-2"
            :hint="emailHint"
            persistent-hint
          >
          </v-text-field>
          <v-text-field
            ref="password"
            v-model="password"
            class="body-2 mt-4"
            dense
            :rules="rules.password"
            :label="$_strings.welcomePage.PASSWORD"
            :type="passwordShow ? 'text' : 'password'"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordShow = !passwordShow"
          ></v-text-field>
          <router-link class="body-2" to="forgot-password">{{$_strings.welcomePage.FORGOT_PASSWORD}}</router-link>
          <v-btn
            rounded
            large
            class="background-denim white--text font-weight-bold body-2 w-100 mt-8"
            type="submit"
          >
            {{$_strings.welcomePage.LOGIN}}
          </v-btn>
        </v-form>
        <div class="d-flex ma-0 mt-6 justify-center body-2 grey--text">
          <p class="text-scorpion" >{{$_strings.welcomePage.CREATE_ACCOUNT}} <router-link to="registration"> {{$_strings.welcomePage.REGISTER}}</router-link></p>
        </div>
      </v-sheet>
      <welcome-footer/>
    </v-col>
  </v-row>
</template>

<script>
import WelcomeFooter from './WelcomeFooter.vue';

export default {
  name: 'Login-form',
  components: {
    WelcomeFooter,
  },
  data() {
    return {
      email: '',
      password: '',
      passwordShow: false,
      emailHint: '',
      ruleEmail: [
        (value) => {
          if (!value) {
            return this.$_strings.messages.validation.REQUIRED(this.$_strings.companyInformation.EMAIL);
          }
          return this.isEmailValid(value) || this.$_strings.messages.validation.EMAIL;
        },
      ],
      rules: {
        email: [],
        password: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Password'),
        ],
      },
      isReadonly: true,
    };
  },
  computed: {
    loginForm() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    navigateToPageMain() {
      if (this.$store.getters['auth/isLoggedIn']) {
        const menu = JSON.parse(this.$store.getters['auth/menu']);
        if (menu[0].child.length > 0) {
          this.$router.replace(menu[0].child[0].url);
          return;
        }
        this.$router.replace(menu[0].url);
      }
    },
    async login() {
      this.email = this.email && this.email.trim();
      this.rules.email = this.ruleEmail;
      this.$nextTick(async () => {
        const valid = this.$refs.loginForm.validate();
        if (valid) {
          try {
            this.$root.$loading.show();
            await this.$store.dispatch('auth/login', this.loginForm);
          } finally {
            this.navigateToPageMain();
            this.$root.$loading.hide();
          }
        }
      });
    },
    clearRulesEmail() {
      this.isReadonly = false;
      this.emailHint = this.$_strings.welcomePage.EMAIL_HINT;
      this.rules.email = [];
    },
    blurRulesEmail() {
      this.emailHint = '';
      this.email = this.email && this.email.trim();
      this.rules.email = this.ruleEmail;
    },
  },

};
</script>

<style scoped lang="scss">
  .title, .title sub{
    color: $--color-doveGray;
    font-size: 16px !important;
  }
  .v-footer {
    background: transparent;
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>
